import { Directive } from '@angular/core';
import { SidebarLinkDirective } from './sidebar-link.directive';

@Directive({
  selector: '[appSidebarDropdown]',
})
export class SidebarDropdownDirective   {
  protected navlinks: Array<SidebarLinkDirective> = [];

 

}
