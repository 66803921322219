import { Routes } from '@angular/router';


export const  CONTENT_ROUTES: Routes = [
  {
    path: 'belshifa-admin/pages',
    loadChildren: () =>
      import('../../pages/content-pages/content-pages.module').then(
        (m) => m.ContentPagesModule
      ),
  },
];
