import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Storage } from '../helpers/storage';
import {Config} from '../../config/config';


@Injectable()
export class AuthService {
  public currentAdminSubject: BehaviorSubject<any>;
  public currentAdmin: Observable<any>;

  constructor(private http: HttpClient, private storage: Storage,private config:Config) {
    this.currentAdminSubject = new BehaviorSubject<any>(
      JSON.parse(this.storage.getCurrentAdmin())
    );
    this.currentAdmin = this.currentAdminSubject.asObservable();
  }

  HostUrl: string = this.config.ApiUrl + 'api/auth-admin';

  public get currentAdminValue(): any {
    return this.currentAdminSubject.value;
  }

  login(email: string, password: string) {
    return this.http.post<any>(`${this.HostUrl}/login`, { email, password });
  }

  logout() {
    this.storage.logout();
    this.currentAdminSubject.next(null);
  }
}
