import { Directive } from '@angular/core';
import { TopMenuLinkDirective } from './topmenu-link.directive';

@Directive({
  selector: '[appTopMenuDropdown]',
})
export class TopMenuDropdownDirective  {
  protected navlinks: Array<TopMenuLinkDirective> = [];

 

}
