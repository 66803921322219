import { Routes} from '@angular/router';


export const Full_ROUTES: Routes = [
  {
    path: 'belshifa-admin/page',
    loadChildren: () =>
      import('../../page/page.module').then((m) => m.PageModule),
  },
];
