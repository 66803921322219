import {
  Directive,
  HostListener,
} from '@angular/core';
import { TopMenuLinkDirective } from './topmenu-link.directive';

@Directive({ selector: '[appTopMenu]' })
export class TopMenuDirective {
  protected navlinks: Array<TopMenuLinkDirective> = [];

  constructor() {}


  public addLink(link: TopMenuLinkDirective): void {
    this.navlinks.push(link);
  }

  public closeOtherLinks(openLink: TopMenuLinkDirective): void {
    this.navlinks.forEach((link: TopMenuLinkDirective) => {
      if (
        link != openLink &&
        (openLink.level.toString() === '1' || link.level === openLink.level)
      ) {
        link.show = false;
      }
    });
  }

  @HostListener('mouseleave', ['$event'])
  onMouseOut() {
    this.navlinks.forEach((link: TopMenuLinkDirective) => {
      link.show = false;
    });
  }
}
