import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface ITemplateConfig {
  layout: {
    variant: string; 
    menuPosition: string;
    customizer: {
      hidden: boolean; 
    };
    navbar: {
      type: string; 
    };
    sidebar: {
     
      collapsed: boolean; 
      size: string; 
      backgroundColor: string; 

   
      backgroundImage: boolean;
      backgroundImageURL: string;
    };
  };
}

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public templateConf: ITemplateConfig = this.setConfigValue();
  templateConfSubject = new BehaviorSubject<ITemplateConfig>(this.templateConf);
  templateConf$ = this.templateConfSubject.asObservable();

  constructor() {}


  setConfigValue() {
    this.templateConf = {
      layout: {
        variant: 'Light',
        menuPosition: 'Side',
        customizer: {
          hidden: true,
        },
        navbar: {
          type: 'Static',
        },
        sidebar: {
          collapsed: false,
          size: 'sidebar-md',
          backgroundColor: 'man-of-steel',
          backgroundImage: true,
          backgroundImageURL: 'assets/img/sidebar-bg/01.jpg',
        },
      },
    };
    return this.templateConf;
  }

  applyTemplateConfigChange(tempConfig: ITemplateConfig) {
    this.templateConf = Object.assign(this.templateConf, tempConfig);
    this.templateConfSubject.next(this.templateConf);
  }
}
